.courts-container {
  font-size: 1rem;
  margin-left: var(--side-margin);
  margin-right: var(--side-margin);
}

.courts-container > h2 {
  text-align: center;
}

.courts-container .header {
  text-align: center;
  height: 260px;
  overflow: hidden;
}

.courts-container .inner {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: auto;
  column-gap: 40px;
  row-gap: 20px;
  align-items: center;
}

.courts-container .inner .map {
  text-align: center;
}

.courts-container .inner .map img {
  width: 100%;
  max-width: 600px;
  border-radius: 0;
  margin: 20px auto;
}

.courts-container .inner .samples {
  text-align: center;
}

.courts-container .inner .samples img {
  display: block;
  width: 100%;
  margin: 20px auto;
  border-radius: 30px;
  max-width: 350px;
}

.courts-container .inner table tr th {
  padding: 5px 20px;
}

@media screen and (max-width: 900px) {
  .courts-container .inner {
    grid-template-columns: 1fr;
  }
  .courts-container .header {
    height: 160px;
  }
  .courts-container .header img {
    height: 160px;
    width: 100%;
  }
  .courts-container .inner .map img {
    max-width: 400px;
  }
}
