.trait-container {
  font-size: 1rem;
  margin-left: var(--side-margin);
  margin-right: var(--side-margin);
}

.trait-container .body {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
}

.trait-container .body .images {
  max-width: 33vw;
}

.trait-container .body .images img {
  width: 100%;
  border-radius: 30px;
  margin: 15px 0;
}

@media screen and (max-width: 900px) {
  .trait-container .body {
    margin-left: 20px;
    margin-right: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  .trait-container .body .images {
    max-width: none;
  }

  .trait-container .body .images img {
    margin: 5px 0;
  }
}
