.art-banner-link {
  display: flex;
  justify-content: space-around;
}

.art-banner {
  width: 20%;
}

.home-container {
  font-size: 1rem;
  margin-left: var(--side-margin);
  margin-right: var(--side-margin);
}

.home-container > h2 {
  text-align: center;
}

.home-side-by-side {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  column-gap: 80px;
  row-gap: 40px;
  align-items: center;
}

.home-side-by-side img {
  width: 100%;
  border-radius: 30px;
}

.text {
  width: 100%;
  text-align: left;
  font-family: 'Open Sans', 'Arial Narrow', Arial, sans-serif;
  font-size: 22px;
}

.tail {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.tail img {
  height: 60px;
}

.tail-2 {
  margin-top: 40px;
}

.title {
  font-family: 'Rowdies';
  text-align: center;
  color: #53cccc;
  font-size: 60px;
  margin-top: 0px;
  margin-bottom: 0px;
}

@media screen and (max-width: 1000px) {
  .home-side-by-side {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  .text {
    font-size: 15px;
  }

  .title {
    font-size: 30px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 540px) {
  .home-container {
    font-size: 0.9rem;
  }
}
