.home-banner-container {
  width: 100%;
  text-align: center;
}

.home-banner-container img {
  object-fit: cover;
  width: 80vw;
}

.home-container {
  font-size: 1rem;
  margin-left: var(--side-margin);
  margin-right: var(--side-margin);
}

.home-container > h2 {
  text-align: center;
}

.home-courts {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: 2fr;
  column-gap: 40px;
  row-gap: 10px;
  align-items: start;
}

.home-courts .court-map {
  grid-column: 2/3;
  grid-row: 1/3;
}

.home-courts .court-map img {
  margin: 30px auto;
  width: 100%;
  max-width: 40rem;
}

.home-courts .court-links {
  margin: 0;
}

.home-courts .court-links div {
  margin: 15px 0;
}

.home-side-by-side {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  column-gap: 40px;
  row-gap: 40px;
  align-items: start;
}

.home-side-by-side img {
  margin-top: 30px;
  width: 100%;
  border-radius: 30px;
}

.text {
  width: 100%;
  text-align: left;
  font-family: 'Open Sans', 'Arial Narrow', Arial, sans-serif;
  font-size: 22px;
}

.tail {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.tail img {
  height: 60px;
}

.tail-links a {
  margin: 0 10px;
}

.tail-2 {
  margin-top: 40px;
}

.title {
  font-family: 'Rowdies';
  text-align: center;
  color: #53cccc;
  font-size: 60px;
  margin-top: 0px;
  margin-bottom: 10px;
}

.court-images {
  text-align: center;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.court-images a img {
  max-width: 100%;
}

.home {
  font-family: 'Open Sans';
  text-align: left;
}

.home-list {
  font-family: 'Open Sans';
  margin-top: 20px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}

.home-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: 'Open Sans', 'Arial Narrow', Arial, sans-serif;
  font-size: 20px;
}

.home-list-item img {
  width: 150px;
  border-radius: 10px;
}

h2 {
  text-align: center;
  font-size: 40px;
}

@media screen and (max-width: 900px) {
  .home-list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .home-list-item {
    flex-direction: row;
    text-align: left;
    column-gap: 20px;
    font-size: 15px;
  }

  .home-list-item img {
    width: 100px;
  }

  .home-courts,
  .home-side-by-side {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  .home-side-by-side img {
    margin-top: 5px;
  }

  .home-courts .court-map {
    grid-column: 1;
    grid-row: 2;
  }

  .home-courts .court-map img {
    margin: 20px auto;
    max-width: auto;
  }

  .home-courts .court-links {
    margin: 20px 0;
  }

  .home-courts .court-links div {
    margin: 20px 0;
  }

  .court-images {
    grid-template-columns: repeat(2, 1fr);
  }

  .text {
    font-size: 1rem;
  }

  .title {
    font-size: 30px;
    margin-bottom: 2;
  }

  .home-list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .home-list-item img {
    width: 70px;
  }
}

@media screen and (max-width: 540px) {
  .home-container {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 450px) {
  .home-list-item img {
    width: 50px;
  }
}
