.navbar-container {
  display: flex;
  margin: 20px;
  align-items: center;
}

.navbar-emblem {
  font-size: 40px;
  font-family: 'Comic Sans';
  flex: 1;
  display: flex;
}

.navbar-emblem a {
  display: flex;
  align-items: center;
  align-content: center;
}

.navbar-emblem img {
  height: 60px;
  width: auto;
}

.navbar-items {
  font-family: 'Open Sans';
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.navbar-items a,
.navbar-items a:visited {
  color: white;
  text-decoration: underline;
}

.navbar-items .courts img {
  color: white;
  fill: white;
  width: 28px;
  transition: fill 250ms ease;
}

.navbar-items .courts img:hover {
  color: var(--hover-color) !important;
  fill: var(--hover-color) !important;
}

.art-link {
  font-family: 'Open Sans';
  font-size: 21px;
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.art-link p.mobile {
  display: none;
}

@media screen and (max-width: 540px) {
  .navbar-container {
    margin-bottom: 50px;
  }

  .navbar-emblem img {
    height: 40px;
  }

  .art-link {
    font-size: 17px;
  }

  .art-link p.mobile {
    display: block;
  }

  .art-link p.desktop {
    display: none;
  }

  .navbar-items .courts {
    position: absolute;
    top: 70px;
    left: 0;
    width: 100vw;
    text-align: center;
  }
  .navbar-items .courts img {
    padding: 0 5px;
  }
}

@media screen and (max-width: 350px) {
  .art-link {
    font-size: 12px;
  }
}
