.navbar-logo {
  color: white;
  fill: white;
  height: 25px;
  width: 25px;

  transition: fill 250ms ease;
}

a:hover,
.navbar-logo:hover {
  color: var(--hover-color) !important;
  fill: var(--hover-color) !important;
}

.navbar-logo-opensea {
  height: 20px;
  width: 20px;
}

@media screen and (max-width: 540px) {
  .navbar-logo {
    height: 25px;
    width: 25px;
  }

  .navbar-logo-opensea {
    height: 22px;
    width: 22px;
  }
}
