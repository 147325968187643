.forms-container {
  font-size: 1rem;
  margin-left: var(--side-margin);
  margin-right: var(--side-margin);
}

.forms-container h2 {
  font-size: 1.5em;
  text-align: left;
}

.forms-container button {
  font-size: 1.25em;
  margin: 5px 0;
}

.forms-container button:disabled {
  font-size: 1.25em;
  background-color: #333;
  color: #000;
}

.forms-container .error {
  color: red;
  border: 1px solid #a00;
  display: inline-block;
  margin-bottom: 20px;
}

.forms-container div.error {
  padding: 10px 15px;
}
.forms-container span.error {
  margin: 5px;
  padding: 1px 5px;
}

.forms-container .error h3 {
  margin: 5px 0;
}

.forms-container form {
  display: block;
  margin: 20px;
}

.forms-container form div {
  margin: 5px 0;
}

.forms-container label {
  display: inline-block;
  width: 150px;
  font-size: 1em;
}

.forms-container input {
  width: 350px;
  font-size: 1em;
}

@media screen and (max-width: 900px) {
  .forms-container h4 {
    font-size: 1em;
    hyphens: auto;
  }
  .forms-container form {
    margin: 20px 0;
  }
  .forms-container label {
    display: block;
  }
  .forms-container input {
    width: 100%;
  }
}
