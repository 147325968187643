.is-gold {
  color: gold;
}

.admin-panel {
  width: 90%;
  margin: auto;
}

.admin-panel h3 {
  font-size: 32px;
  margin: 5px 0;
}
.admin-panel h4 {
  font-size: 28px;
  margin: 5px 0;
  color: #ccc;
}

.admin-panel .error {
  color: red;
  border: 1px solid #a00;
  padding: 10px;
  display: inline-block;
}

.admin-panel .error h3 {
  margin: 5px 0;
}

.admin-panel .error div {
  font-family: monospace;
}

.admin-panel label span {
  font-size: 12px;
}

.admin-panel input {
  width: 90%;
  max-width: 800px;
  padding: 0 10px;
}

.admin-panel button {
  margin-left: 20px;
}

.admin-panel button:disabled {
  background-color: #666;
  color: #999;
}
.admin-panel button:disabled:hover {
  box-shadow: none;
  cursor: default;
}

.admin-panel .results {
  margin: 30px 0;
}

.admin-panel .results pre {
  border: 1px solid #333;
  background-color: #666;
  width: fit-content;
  padding: 20px;
}

p .admin-panel p {
  margin-bottom: 0px;
}

.admin-request {
  margin-top: 20px;
}

.admin-panel textarea {
  width: 90%;
  max-width: 800px;
  height: 100px;
  display: block;
  margin: 10px 0 30px;
  padding: 10px;
}

.admin-panel table {
  border-collapse: collapse;
  border: 1px solid #666;
}

.admin-panel tr {
  border: 1px solid #aaa;
}

.admin-panel th {
  background-color: #333;
}

.admin-panel td,
.admin-panel th {
  text-align: center;
  padding: 7px 10px;
  border: 1px solid #aaa;
}

.admin-panel td.cards {
  text-align: left;
}

.admin-panel td pre {
  margin: 0;
}

.admin-panel td span.card {
  display: inline-block;
  text-align: center;
  border: 1px solid #999;
  margin: 2px;
  padding: 5px;
  background-color: #fff;
  color: #000;
}

.admin-panel td span.card .hearts,
.admin-panel td span.card .diamonds {
  color: red;
}

.admin-panel td span.gold {
  background-color: gold;
}

.admin-panel td span span.small {
  font-size: 10px;
  display: block;
}
