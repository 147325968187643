.team-container {
  margin-left: var(--side-margin);
  margin-right: var(--side-margin);
  text-align: center;
}

.team-members {
  column-gap: 30px;
  margin-top: 20px;
  display: grid;
  align-items: top;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 300px));
}

.team-member {
  width: 100%;
  align-items: center;
  font-family: 'Open Sans';
  font-size: 25px;
}

.team-member img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

.team-socials {
  text-align: center;
}

.team-socials a {
  margin: 0 10px;
}

@media screen and (max-width: 1000px) {
  .team-members {
    flex-direction: column;
    row-gap: 20px;
  }

  .team-member {
    width: 100%;
  }

  .team-member img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }
}

@media screen and (max-width: 540px) {
  .team-members {
    grid-template-columns: repeat(auto-fit, minmax(225px, 100%));
  }
}
